import React, { useEffect,  useRef, useState } from "react";
import { Grid, LinearProgress, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";


// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";
import FixturesToolbar from "./components/FixturesToolbar";

// styles
import useStyles from "./styles";

// data
import { ControlCameraOutlined } from "@material-ui/icons";

//logo
import blinkingGreenDot from "../../images/blinking_green_dot.gif";
import InfoIcon from "@material-ui/icons/Info";

// Luxon
const { DateTime } = require("luxon");

const SERVER = 'https://botpredictor.noe.casa'
    

export default function Fixtures(props) {
  function calculatePoints(fixture, prediction) {
    var CORRECT_RESULT = 5
    var GOAL_DIFF_MAX = 2
    var GOAL_MATCH_3_OR_LESS = 1
    var GOAL_MATCH_ABOVE_3 = 3
    var PERFECT_SCORE = 2

    var is_complete_prediction = prediction !== undefined && 'home_score' in prediction && 'away_score' in prediction;
    if (!is_complete_prediction && fixture['locked']) {
      return 0;
    }
    if (!is_complete_prediction || fixture['status'] == 'pre') {
      return 'TBD';
    }
    var points = 0;
    var result_diff = fixture['home_score'] - fixture['away_score'];
    var prediction_diff = prediction['home_score'] - prediction['away_score'];
    var result_direction = result_diff !== 0 ? result_diff / Math.abs(result_diff) : 0;
    var prediction_direction = prediction_diff !== 0 ? prediction_diff / Math.abs(prediction_diff) : 0;
    
    var points_breakdown = [];

    if (result_direction === prediction_direction) {
      points += CORRECT_RESULT
      points_breakdown.push(`${CORRECT_RESULT}CR`)
      var goal_diff_points = Math.max(GOAL_DIFF_MAX - Math.abs(result_diff - prediction_diff), 0)
      points += goal_diff_points
      if (goal_diff_points > 0) {
        points_breakdown.push(`${goal_diff_points}GD`)
      }
    }
    if (fixture['home_score'] == prediction['home_score']) {
      if (fixture['home_score'] > 3) {
        points += GOAL_MATCH_ABOVE_3
        points_breakdown.push(`${GOAL_MATCH_ABOVE_3}HG4+`)
      } else {
        points += GOAL_MATCH_3_OR_LESS
        points_breakdown.push(`${GOAL_MATCH_3_OR_LESS}HG`)
      }
    }
    if (fixture['away_score'] == prediction['away_score']) {
      if (fixture['away_score'] > 3) {
        points += GOAL_MATCH_ABOVE_3
        points_breakdown.push(`${GOAL_MATCH_ABOVE_3}AG4+`)
      } else {
        points += GOAL_MATCH_3_OR_LESS
        points_breakdown.push(`${GOAL_MATCH_3_OR_LESS}AG`)
      }
    }
    if (fixture['home_score'] == prediction['home_score'] && fixture['away_score'] == prediction['away_score']) {
      points += PERFECT_SCORE
      points_breakdown.push(`${PERFECT_SCORE}PS`)
    }
    points_breakdown = points_breakdown.join(', ');

    if (fixture['live']) {
      return <span className={`${classes.points} ${classes.liveData}`}>
        <Typography>{points}</Typography>
        <img src={blinkingGreenDot} alt="logo" className={classes.blinkingGreenDot} />
        {points > 0 ? <Tooltip placement='bottom-start' title={points_breakdown} leaveDelay={500} leaveTouchDelay={3000}>
          <InfoIcon className={classes.infoIcon} />
        </Tooltip> : ''}
      </span>;
    } else {
      return <span className={classes.points}>
        <Typography>{points}</Typography>
        {points > 0 ? <Tooltip placement='bottom-start' title={points_breakdown} leaveDelay={500} leaveTouchDelay={3000}>
          <InfoIcon className={classes.infoIcon} />
        </Tooltip> : ''}
      </span>;
    }

  }

  function setPrediction(match_id, prediction_type, value) {
    var newPredictions = {...predictionsValue.current};
    
    if (!(match_id in newPredictions)) {
      newPredictions[match_id] = {}
    }
    var prediction_key = prediction_type === 'home' ? 'home_score' : 'away_score';
    if ((value === '' || value === NaN) && prediction_key in newPredictions[match_id]) {
      newPredictions[match_id][prediction_key] = null
    } else {
      newPredictions[match_id][prediction_key] = parseInt(value);
    }
    setPredictions(newPredictions);
  }

  function guardar() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          'league': props.league,
          'name': localStorage.getItem('name'),
          predictions: predictionsValue.current,
        })
    };
  
    fetch(SERVER + "/predictions", requestOptions)
      .then(result => result.json())
      .then(
        (json) => {
          setGuardarSnackBarOpen(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.debug(error);
          alert('Could not guardar')
        },
      )
  }

  function getActualScoreElement(fixture) {
    if (fixture['live']) {
      return <span className={classes.liveData}>{fixture['home_score']}<span> - </span>{fixture['away_score']} <img src={blinkingGreenDot} alt="logo" className={classes.blinkingGreenDot} /> </span>;
    }
    else {
      return fixture['home_score'] !== undefined ? <span>{fixture['home_score']}<span> - </span>{fixture['away_score']}</span> : 'TBD';
    }
  }
  
  var classes = useStyles();
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [predictions, setPredictions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [guardarSnackBarOpen, setGuardarSnackBarOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const predictionsValue = useRef(predictions);
  

  useEffect(() => {
      var fixtures;
      var params = {
        'league': props.league,
      }
    var url = new URL(SERVER + '/fixtures')
      url.search = new URLSearchParams(params).toString();
      fetch(url)
      .then((response) => response.json())
      .then((json) => {
        fixtures = json;
        var params = {
          'league': props.league,
          'name': localStorage.getItem('name'),
        }
        url = new URL(SERVER + '/predictions')
        url.search = new URLSearchParams(params).toString();
        return fetch(url);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.debug(error);
        alert('Could not load fixtures')
      })
      .then(response => response.json())
      .then(json => {
          var savedPredictions = json
          var admin_mode = new URLSearchParams(props.location.search).get("admin")
          var headers = [
            {
              'name': 'Date',
              'options': {
                customHeadLabelRender: (columnMeta) => {
                  return <Typography variant='h5'>Date</Typography>
                },
                filter: false
              }
            },
            {
              'name': 'Teams',
              'options': {
                customHeadLabelRender: (columnMeta) => {
                  return <Typography variant='h5'>Teams</Typography>
                },
                filter: false
              }
            },
            {
              'name': 'Status',
              'options': {
                customHeadLabelRender: (columnMeta) => {
                  return <Typography variant='h5'>Status</Typography>
                },
                filterList: admin_mode ? [] : ['Scheduled'],
              }
            },
            {
              'name': 'Prediction',
              'options': {
                customHeadLabelRender: (columnMeta) => {
                  return <Typography variant='h5'>Prediction</Typography>
                },
                filter: false
              }
            },
            {
              'name': 'Actual Score',
              'options': {
                customHeadLabelRender: (columnMeta) => {
                  return <Typography variant='h5'>Actual Score</Typography>
                },
                filter: false
              }
            },
            {
              'name': 'Points',
              'options': {
                customHeadLabelRender: (columnMeta) => {
                  return <Typography variant='h5'>Points</Typography>
                },
                filter: false
              }
            },
          ];
          var rows = [];
          for (const fixture of fixtures) {
            let date = DateTime.fromISO(fixture['date'], { zone: 'utc'});
            date = date.toLocal();
            let lock_time = date.minus({ minutes: 10 });
            let now = DateTime.local();
            var admin_mode = new URLSearchParams(props.location.search).get("admin")
            let is_locked = now >= lock_time && admin_mode === null ? true : false;
            fixture['locked'] = is_locked;

            let teams = <span className={classes.teamNames}>
              <img src={fixture['home_flag_url']} alt="logo" className={classes.teamFlag} />
              <Typography>
                {fixture['home_team']} vs {fixture['away_team']}
              </Typography>
              <img src={fixture['away_flag_url']} alt="logo" className={classes.teamFlag} />
            </span>;

            let status = fixture['status_detail'];
            let home_prediction = '';
            let away_prediction = '';
            if (fixture['id'] in savedPredictions && savedPredictions[fixture['id']]['home_score'] !== undefined) {
              home_prediction = savedPredictions[fixture['id']]['home_score']
            }
            if (fixture['id'] in savedPredictions && savedPredictions[fixture['id']]['away_score'] !== undefined) {
              away_prediction = savedPredictions[fixture['id']]['away_score']
            }
            let home_score = undefined;
            let away_score = undefined;
            if ('home_score' in fixture && 'away_score' in fixture) {
              home_score = fixture['home_score'];
              away_score = fixture['away_score'];
            }
            
            rows.push([
              fixture['date_display'], 
              teams,
              status, 
              is_locked ? 
              <span>{home_prediction}<span> - </span>{away_prediction}</span> 
              :
              <div>
                <TextField 
                  type='number'
                  defaultValue={home_prediction}
                  InputProps={{ inputProps: { min: 0 } }}
                  className={classes.predictionInput}
                  onChange={e => setPrediction(fixture['id'], 'home', e.target.value)}
                />
                <span className={classes.hyphen}>-</span>
                <TextField 
                  type='number'
                  defaultValue={away_prediction}
                  InputProps={{ inputProps: { min: 0 } }}
                  className={classes.predictionInput}
                  onChange={e => setPrediction(fixture['id'], 'away', e.target.value)}
                />
              </div>, 
              <div>{getActualScoreElement(fixture)}</div>,
              calculatePoints(fixture, savedPredictions[fixture['id']]),
            ])
          }
          setHeaders(headers);
          setRows(rows);
          setIsLoading(false);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.debug(error);
          alert('Could not get predictions')
        }
      )
  }, []);


  useEffect(() => {
    let changed = predictions !== predictionsValue.current;
    predictionsValue.current = predictions;
    
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (changed) {
      setTypingTimeout(setTimeout(function () {
        console.log('Auto-guardaring after 4 seconds idle!');
        guardar();
      }, 3000))
    }
  }, [predictions])

  const onGuardarSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setGuardarSnackBarOpen(false);
  };

  return (
    <>
      <PageTitle title={props.title} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title=""
            data={rows}
            columns={headers}
            options={
              isLoading ? 
              {
                filterType: "checkbox",
                selectableRows: 'none',
                download: false,
                print: false,
                viewColumns: false,
                pagination: false,
                textLabels: {
                  body: {
                      noMatch: <LinearProgress />,
                  },
                },
              } 
              :
              {
                filterType: "checkbox",
                selectableRows: 'none',
                download: false,
                print: false,
                viewColumns: false,
                // customToolbar: () => {
                //   return (
                //     <FixturesToolbar
                //       onGuardarClicked={() => {guardar()}}
                //     />
                //   );
                // },
                // rowsPerPage: 200,
                pagination: false,
                textLabels: {
                  body: {
                      noMatch: 'No fixtures found',
                  },
                },
              }
            }
          />
        </Grid>
      </Grid>
      <Snackbar 
        open={guardarSnackBarOpen} 
        autoHideDuration={3000} 
        message='Your predictorings have been guardared for you' 
        onClose={onGuardarSnackbarClose} 
      />
    </>
  );
}
