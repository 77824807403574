import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    predictionInput: {
        width: '5rem',
    },
    hyphen: {
        fontWeight: 'bold',
        margin: '0 1rem 0 1rem',
    },
    points: {
        display: 'flex',
        flexDirection: 'row',
    },
    liveData: { 
        color: 'green',
        fontWeight: 'bold',
    },
    infoIcon: {
        height: '1.2rem',
        marginLeft: '0.2rem',
    },
    blinkingGreenDot: {
        height: '0.6rem',
    },
    teamNames: {
        display: 'flex',
        flexDirection: 'row',
    },
    teamFlag: {
        height: '1.5rem',
        margin: '0 1rem 0 1rem',
    }
}));