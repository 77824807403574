import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
  },
  saveIcon: {
  }
};

class FixturesToolbar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Guardar"}>
          <IconButton className={classes.iconButton} onClick={this.props.onGuardarClicked}>
            <SaveIcon className={classes.saveIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "FixturesToolbar" })(FixturesToolbar);